import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  childrenFriendly: {
    justifyContent: "left",
    textAlign: "left",
    height: "max-content",
    textTransform: "unset",
    minWidth: "unset",
    "&:hover": {
      opacity: 0.9,
    },
  },
});

const ChildrenFriendlyButton = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Button
      classes={{
        root: classes.childrenFriendly,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ChildrenFriendlyButton;
