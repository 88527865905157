/* eslint-disable no-console */
import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AlertContainer from "components/AlertContainer";
import { TextField, Box, Button, Typography } from "@material-ui/core";
import * as yup from "yup";
import APIEndpoints from "APIEndpoints";
import { useFormik } from "formik";
import useHit from "hooks/useHit";
import { useBlockchain } from "store/Blockchain";
import { useTranslation } from "i18n";
import DialogForm from "./DialogForm";

const useStyles = makeStyles({
  inputs: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 8,
    "& div": {
      width: "100%",
    },
  },
});
const validationSchema = yup.object({
  transferValue: yup
    .number()
    .min(0, "Value cannot be less than 0")
    .required("Transfer value is required"),
  transferAddress: yup.string().required("Transfer address is required"),
});
const TransferCKBDialog = ({
  open,
  title,
  cross,
  subtitle,
  actions,
  onClose,
  children,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const alertContainer = useRef();
  const hit = useHit();
  const { balance, signTransaction } = useBlockchain();
  const balanceFee = balance - 0.00001;
  const formik = useFormik({
    initialValues: {
      transferValue: "",
      transferAddress: "",
    },
    validationSchema,
    onSubmit: async (data) => {
      if (balanceFee < data.transferValue) {
        formik.setFieldError("transferValue", t("NOT_ENOUGHT_BALANCE"));
        return;
      }
      
      if (balanceFee - data.transferValue !== 0 && balanceFee - data.transferValue < 61) {
        formik.setFieldError("transferValue", t("REMAINING_AMOUNT_LESS_THAN_61_CKB"));
        return;
      }
      const payload = {
        amountCKB: parseFloat(data.transferValue),
        targetNervosAddress: data.transferAddress,
      };
      const response = await hit(APIEndpoints.USER.TRANSFER_CKB, payload);
      if (response.error) {
        alertContainer.current.setAlert({
          color: "error",
          severity: "error",
          text: t(response.error) || t("CANNOT_PROCESS_TRANSACTION"),
        });
      } else {
        try {
          const txHash = await signTransaction(response.transactionToSign);
          console.log("txHash: ", txHash);
          alertContainer.current.setAlert({
            color: "success",
            severity: "success",
            text: t("SUCCESSFULLY_TRANSFER"),
          });
          setTimeout(() => {
            onClose();
          }, 2000);
        } catch (error) {
          alertContainer.current.setAlert({
            color: "error",
            severity: "error",
            text: error?.message || t("CANNOT_PROCESS_TRANSACTION"),
          });
          setTimeout(() => {
            onClose();
          }, 4000);
        }
      }
    },
  });
  return (
    <DialogForm
      cross
      open
      onClose={onClose}
      title="Transfer your CKB Balance"
      fullScreen={false}
      actions={(
        <Box>
          <Button
            disabled={formik.isSubmitting}
            onClick={onClose}
            variant="text"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
            variant="contained"
            color="secondary"
          >
            Transfer
          </Button>
        </Box>
      )}
      {...rest}
    >
      <Box className={classes.inputs}>
        <Box mt={2}>
          <Typography variant="body2">
            Transfer, available: $
            {parseFloat(balanceFee).toFixed(1)}
            {" "}
            CKB
          </Typography>
        </Box>
        <TextField
          name="transferValue"
          value={formik.values.transferValue}
          onChange={formik.handleChange}
          error={formik.touched.transferValue && !!formik.errors.transferValue}
          helperText={formik.touched.transferValue && formik.errors.transferValue}
          multiline
          type="number"
          color="secondary"
          InputProps={{ endAdornment: <Button onClick={() => formik.setFieldValue("transferValue", balanceFee)}>MAX</Button> }}
        />
        <br />
        <Box mt={2}>
          <Typography variant="body2">Transfer address</Typography>
        </Box>
        <TextField
          name="transferAddress"
          color="secondary"
          value={formik.values.transferAddress}
          onChange={formik.handleChange}
          multiline
          error={formik.touched.transferAddress && !!formik.errors.transferAddress}
          helperText={formik.touched.transferAddress && formik.errors.transferAddress}
        />
      </Box>
      <AlertContainer ref={alertContainer} />
    </DialogForm>
  );
};
export default TransferCKBDialog;
