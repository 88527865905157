module.exports = {
  CALLBACK_FACEBOOK: "/cb/facebook",
  CALLBACK_GOOGLE: "/cb/google",
  CALLBACK_METAMASK: "/cb/metamask",
  INDEX: "/",
  SIGN_IN: "/sign-in",
  SIGN_IN_AND_REDIRECT: (route) => {
    if (route) {
      return `/sign-in?redirect=${encodeURIComponent(route)}`;
    }
    return "/sign-in";
  },
  GALLERY: "/gallery",
  MY_ROSTER: "/my-roster",
  MY_COLLECTION: "/my-collection",
  STORE: "/store",
  PAYMENT_HISTORY: "/payment-history",
  GAME_PAGE: "/game-page",
  LEADERBOARD: "/leaderboard",
  ALL_CARDS: "/all-cards",
  ROADMAP: "/roadmap",
  WHITEPAPER: "/whitepaper",
  HOME: "/home",
  SLAMIES_CLAIM: "/slammies-claim",
  USER_PROFILE: (userId) => ({ INDEX: `/user/${userId}` }),
  ITEM: (itemId) => ({
    PAY_WITH_CARD: `/store/item/${itemId}/pay-with-card`,
    PAY_WITH_CKB: `/store/item/${itemId}/pay-with-ckb`,
    INDEX: `/store/item/${itemId}`,
  }),
  GROUP: (group) => ({
    INDEX: `/store/${group}`,
  }),
};
