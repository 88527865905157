import { useRouter } from "next/router";
import Link from "next/link";
import { Button, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  activeClassName: {
    "&::after": {
      content: "''",
      width: "calc(100% - 16px)",
      height: 8,
      background: theme.custom.color.white,
      position: "absolute",
      bottom: 0,
    },
  },
}));

const ActiveLink = ({ href, title, onClick }) => {
  const classes = useStyles();
  const { asPath } = useRouter();

  return (
    <Link href={href} passHref>
      <Button
        className={href === asPath ? classes.activeClassName : null}
        onClick={onClick}
      >
        <Typography variant="button">{title}</Typography>
      </Button>
    </Link>
  );
};

export default ActiveLink;
