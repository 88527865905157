import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
    "& > :first-child": {
      marginLeft: 0,
    },
  },
}));

const HorizontalArragement = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container} {...rest}>
      {children}
    </Box>
  );
};

export default HorizontalArragement;
