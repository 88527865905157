/* eslint-disable react/display-name */
import EditUserDialog from "components/Dialogs/EditUserDialog";
import Popoverize from "components/Popoverize";
import { useTranslation } from "i18n";
import { useDialogs } from "store/Dialogs";
import { Box, MenuItem, ListItemText, Typography, ListItem, IconButton, makeStyles } from "@material-ui/core";
import useHit from "hooks/useHit";
import { useSession } from "store/Session";
import APIEndpoints from "APIEndpoints";
import { useRouter } from "next/router";
import routes from "routes";
import { Link as LinkIcon, Close as CloseIcon } from "@material-ui/icons";
import { useBlockchain } from "store/Blockchain";
import { useSnackbar } from "notistack";
import { chopString, copyToClipboard } from "utils";
import TransferCKB from "components/Dialogs/TransferCKB";
import QRCode from "react-qr-code";
import CKBPurchase from "components/Dialogs/CKBPurchase";

// icons
import NervosIcon from "assets/icons-profile/nervos_icon.png";
import Slammies from "assets/icons-profile/slammies_icon.png";
import Transfer from "assets/icons-profile/transfer_icon.png";
import Delete from "assets/icons-profile/delete_icon.png";
import Edit from "assets/icons-profile/edit_icon.png";
import History from "assets/icons-profile/history_icon.png";
import SignOut from "assets/icons-profile/sign-out_icon.png";
import Profile from "assets/icons-profile/profile_icon.png";

const useStyles = makeStyles((theme) => ({
  slammies: {
    background: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      background: theme.palette.primary.main,
      color: "white",
    },
  },
}));

const ProfilePopover = ({ children, ...rest }) => {
  const { t } = useTranslation();
  const dialogs = useDialogs();
  const classes = useStyles();
  const { addressCKB, balance } = useBlockchain();
  const { data: session, refresh } = useSession();
  const router = useRouter();
  const hit = useHit();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const isDev = process.env.NEXT_PUBLIC_ENABLE_DEV_TOOLS === "yes, enable dev tools";
  
  const signOut = async () => {
    const shouldCloseSession = await dialogs.confirm({
      title: t("ARE_YOU_SURE_SIGN_OUT"),
      maxWidth: "xs",
      fullScreen: false,
    });
    if (shouldCloseSession) {
      localStorage.removeItem("session");
      refresh();
    }
  };

  const onDelete = async () => {
    const shouldDelete = await dialogs.confirm({
      title: `${t("DELETE_ACCOUNT")}?`,
      maxWidth: "xs",
      fullScreen: false,
      continueLabel: t("DELETE"),
    });
    if (shouldDelete) {
      const { error } = await hit(APIEndpoints.USER.DELETE);
      if (!error) {
        localStorage.removeItem("session");
        window.location.reload();
      }
    }
  };

  const onEditUser = () => {
    dialogs.create({
      DialogType: EditUserDialog,
    });
  };

  const onPaymentHistory = () => {
    router.push(routes.PAYMENT_HISTORY);
  };

  const onMyProfile = () => {
    router.push(routes.USER_PROFILE(session.userId).INDEX);
  };

  const slammiesClaim = () => {
    router.push(routes.SLAMIES_CLAIM);
  };
  const paymentPopup = () => {
    const DialogWrapper = (defaultProps) => (
      <CKBPurchase
        {...defaultProps}
      />
    );
    dialogs.create({ DialogType: DialogWrapper }, { className: classes.popup });
  };

  const onBalance = () => {
    const onCopyAddress = () => {
      enqueueSnackbar("CKB address copied successfully", {
        variant: "success",
        persist: false,
        action: (key) => (
          <IconButton aria-label="delete" onClick={() => closeSnackbar(key)}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        ),
      });
    };

    const dialog = dialogs.create({
      title: "This is your CKB Balance",
      children: (
        <>
          <Typography>
            You can inspect your CKB account, balance, transactions and more in the explorer!
          </Typography>
          <Box marginTop={2} textAlign="center">
            <QRCode value={addressCKB} />
            <br />
            <Typography
              color="textSecondary"
              style={{
                textAlign: "center",
                textTransform: "none",
                overflowWrap: "break-word",
                maxWidth: "100%",
              }}
            >
              {addressCKB}
            </Typography>
          </Box>
        </>
      ),
      actions: [
        { text: "Cancel", onClick: () => dialog.close() },
        { text: "Copy address", onClick: () => { copyToClipboard(addressCKB); onCopyAddress(); } },
        { text: "Need CKB?", onClick: () => { paymentPopup(); } },
        { text: "Go to the explorer",
          endIcon: <LinkIcon />,
          variant: "outlined",
          onClick: () => {
            window.open(`${process.env.NEXT_PUBLIC_NERVOS_URL}/${addressCKB}`, "_blank");
          } },
      ],
    });
  };

  const onTransfer = () => {
    dialogs.confirm({ DialogType: TransferCKB });
  };

  return (
    <Popoverize
      {...rest}
      options={(
        <>
          <MenuItem
            onClick={onBalance}
          >
            <ListItemText
              primary={(
                <Box display="flex">
                  <Box display="flex">
                    <Box padding={0.5}>
                      <img alt="" src={NervosIcon.src} width={20} />
                    </Box>
                    <Box>
                      <Typography style={{ textTransform: "none" }}>
                        {t("BALANCE")}
                        :
                        {" "}
                        {parseFloat(balance).toFixed(1)}
                        {" "}
                        CKB
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              secondary={(
                <Typography variant="caption">{chopString(addressCKB, 12)}</Typography>
              )}
            />
          </MenuItem>
          <MenuItem className={classes.slammies} id="mibEditProfile" onClick={slammiesClaim}>
            <Box display="flex">
              <Box display="flex">
                <Box padding={0.5}>
                  <img alt="" src={Slammies.src} width={20} />
                </Box>
                <Box>
                  <Typography style={{ textTransform: "none" }}>
                    {t("SLAMIE_CLAIM")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </MenuItem>
          <MenuItem onClick={onTransfer}>
            <Box display="flex">
              <Box display="flex">
                <Box padding={0.5}>
                  <img alt="" src={Transfer.src} width={20} />
                </Box>
                <Box>
                  <Typography style={{ textTransform: "none" }}>
                    {t("TRANSFER")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </MenuItem>
          {isDev && (
            <MenuItem id="mibDeleteAccount" onClick={onDelete}>
              <Box display="flex">
                <Box display="flex">
                  <Box padding={0.5}>
                    <img alt="" src={Delete.src} width={20} />
                  </Box>
                  <Box>
                    <Typography style={{ textTransform: "none" }}>
                      {t("DELETE_ACCOUNT")}
                    </Typography>
                  </Box>
                </Box>
              </Box>

            </MenuItem>
          )}
          <MenuItem id="mibEditProfile" onClick={onEditUser}>
            <Box display="flex">
              <Box display="flex">
                <Box padding={0.5}>
                  <img alt="" src={Edit.src} width={20} />
                </Box>
                <Box>
                  <Typography style={{ textTransform: "none" }}>
                    {t("EDIT_PROFILE")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </MenuItem>
          <MenuItem id="mibEditProfile" onClick={onMyProfile}>
            <Box display="flex">
              <Box display="flex">
                <Box padding={0.5}>
                  <img alt="" src={Profile.src} width={20} />
                </Box>
                <Box>
                  <Typography style={{ textTransform: "none" }}>
                    {t("MY_PROFILE")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </MenuItem>
          <MenuItem id="mibPaymentHistory" onClick={onPaymentHistory}>
            <Box display="flex">
              <Box display="flex">
                <Box padding={0.5}>
                  <img alt="" src={History.src} width={20} />
                </Box>
                <Box>
                  <Typography style={{ textTransform: "none" }}>
                    {t("PAYMENT_HISTORY")}
                  </Typography>
                </Box>
              </Box>
            </Box>

          </MenuItem>
          <MenuItem id="mibSignOut" onClick={signOut}>
            <Box display="flex">
              <Box display="flex">
                <Box padding={0.5}>
                  <img alt="" src={SignOut.src} width={20} />
                </Box>
                <Box>
                  <Typography style={{ textTransform: "none" }}>
                    {t("SIGNOUT")}
                  </Typography>
                </Box>
              </Box>
            </Box>

          </MenuItem>
          <ListItem
            dense
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
          />
        </>
      )}
    >
      {children}
    </Popoverize>
  );
};

export default ProfilePopover;
