const LettersCSDRed = (props) => (
  <svg
    width={206}
    height={65}
    viewBox="0 0 206 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M64.45.91H.12v64.33h64.33V.91z" fill="#D90404" />
    <path
      d="M31.51 33.59c0 3.43.38 5.92 1.15 7.48.77 1.56 2.05 2.34 3.86 2.34 1.8 0 4.81-.5 9-1.49l2.17 10.76c-4.42 1.4-8.69 2.1-12.79 2.1-6.27 0-10.84-1.79-13.71-5.38-2.87-3.59-4.3-8.93-4.3-16.04 0-7.11 1.49-12.54 4.47-16.31 2.98-3.77 7.83-5.65 14.55-5.65 3.93 0 7.85.77 11.78 2.3l-2.23 12.05c-4.47-1.31-7.52-1.96-9.17-1.96-1.65 0-2.85.78-3.62 2.33-.78 1.54-1.16 4.04-1.16 7.47z"
      fill="#F6F6F6"
    />
    <path
      d="M135.06.91H70.73v64.33h64.33V.91zM205.67.91h-64.33v64.33h64.33V.91z"
      fill="#D90404"
    />
    <path
      d="M87.29 25.56c0-4.45 1.35-7.91 4.04-10.38 2.7-2.47 6.44-3.71 11.22-3.71 4.79 0 9.72.45 14.8 1.35l-1.55 11.87c-5.62-1.12-9.3-1.69-11.06-1.69-1.76 0-2.63.54-2.63 1.62 0 .4.13.69.4.84.27.16.47.28.61.37.13.09.39.2.78.34.38.13.7.26.94.37.25.11 1.06.45 2.43 1.01s2.4 1.01 3.1 1.35c.7.34 1.65.89 2.87 1.65 3.51 2.29 5.26 5.66 5.26 10.11 0 4.45-1.33 7.9-3.98 10.35-2.65 2.45-6.46 3.67-11.43 3.67s-9.83-.61-14.6-1.82l.94-11.39c6.02 1.53 10.09 2.29 12.2 2.29 1.39 0 2.09-.49 2.09-1.48 0-.45-.17-.81-.51-1.08-.34-.27-.83-.56-1.48-.88-.65-.31-1.61-.73-2.87-1.25s-2.32-.97-3.17-1.35c-.85-.38-1.87-.98-3.03-1.79-3.57-2.46-5.37-5.92-5.37-10.37zM192.19 32.14c0 15.03-6.87 22.55-20.6 22.55h-16.77V11.48h17.12c7.38 0 12.6 1.78 15.66 5.32 3.06 3.54 4.59 8.66 4.59 15.34zm-16.21 9.25c.7-1.62 1.04-4.32 1.04-8.11 0-3.78-.38-6.47-1.15-8.07-.77-1.6-2.36-2.4-4.77-2.4h-1.46v21.01h1.74c2.37.01 3.9-.8 4.6-2.43z"
      fill="#F6F6F6"
    />
  </svg>
);

export default LettersCSDRed;
