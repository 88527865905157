import { Typography, Box, makeStyles, Divider } from "@material-ui/core";
import DialogForm from "./DialogForm";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "underline",
    padding: theme.spacing(1),

  },
  titles: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1.3),
  },
}));

const CKBPurchase = ({
  open,
  title,
  cross,
  subtitle,
  actions,
  onClose,
  onCancel,
  children,
  cash,
  ...rest
}) => {
  const classes = useStyles();

  const wrappedOnClose = () => {
    if (onCancel) onCancel();
    onClose();
  };
  return (
    <DialogForm
      cross
      open
      fullScreen={false}
      onClose={wrappedOnClose}
      maxWidth="xs"
      {...rest}
    >
      <Typography variant="h4" className={classes.titles}>Get more CKB</Typography>
      <Typography variant="caption">
        If you do not have funds you can buy in these options
      </Typography>
      <Box mt={1}>
        <Box padding={0.2} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" className={classes.link} color="primary">
            <a href="https://buy.chainbits.com/" rel="noreferrer" target="_blank">ChainBits</a>
          </Typography>
          {/* <Typography className={classes.link} variant="body2">
            <a href="https://google.com.ar" rel="noreferrer" target="_blank">Guide to buying on Chainbits</a>
          </Typography> */}
        </Box>
        <Divider style={{ marginBottom: "10px", marginTop: "10px", background: "#c2c2c2" }} />
        <Box padding={0.2} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" className={classes.link} color="primary">
            <a href="https://www.binance.com/es" rel="noreferrer" target="_blank">Binance</a>
          </Typography>
          {/* <Typography className={classes.link} variant="body2">
            <a href="https://google.com.ar" rel="noreferrer" target="_blank">Guide to buying on Binance</a>
          </Typography> */}
        </Box>
        <Divider style={{ marginBottom: "10px", marginTop: "9px", background: "#c2c2c2" }} />
        <Box padding={0.2} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="body1" className={classes.link} color="primary">
            <a href="https://www.yokaiswap.com/" rel="noreferrer" target="_blank">YokaiSwap</a>
          </Typography>
          {/* <Typography className={classes.link} variant="body2">
            <a href="https://google.com.ar" rel="noreferrer" target="_blank">Guide to buying on YokaiSwap</a>
          </Typography> */}
        </Box>
      </Box>
    </DialogForm>
  );
};

export default CKBPurchase;
