/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "i18n";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  AppBar,
  Container,
  Drawer,
  Button,
  Box,
  Avatar,
  IconButton,
  Typography,
  useMediaQuery,
  MenuItem,
  Popover,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ActiveLink from "components/ActiveLink";
import HorizontalArragement from "components/HorizontalArragement";
import ProfilePopover from "components/MagicButtons/ProfilePopopver";
import APIEndpoints from "APIEndpoints";
import { useDialogs } from "store/Dialogs";
import Link from "next/link";
import routes from "routes";
// import { useDialogs } from "store/Dialogs";
import { useSession } from "store/Session";
import LogoCSDBlack from "components/Logos/LogoCSDBlack";
import LettersCSDRed from "components/Logos/LettersCSDRed";
import { useResource } from "hooks";

// icons
import nervosIcon from "assets/social-media/nervos.png";
import ratherIcon from "assets/social-media/rather.png";
import twitterIcon from "assets/social-media/twitter.png";
import discordIcon from "assets/social-media/discord.png";
import mediumIcon from "assets/social-media/medium.png";
import redditIcon from "assets/social-media/reddit.png";
import gravityGamingIcon from "assets/social-media/gravitygaming.png";
// import ClosedGame from "components/Dialogs/ClosedGame";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: 80,
    backgroundColor: theme.palette.secondary.main,
  },
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 80,
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  container: {
    flexDirection: "column",
    height: "100%",
    display: "flex",
  },
  footer: {
    background: theme.custom.color.black,
    padding: "2rem",
  },
  logo: {
    width: 150,
  },
  links: {
    display: "flex",
    columnGap: 16,
    alignItems: "center",
    "& a span": {
      fontSize: 27,
      fontWeight: "bold",
      fontFamily: "Paytone One",
      color: theme.custom.color.white,
    },
  },
  buttonHere: {
    padding: 0,
    margin: 0,
    color: theme.palette.primary.main,
  },
  mobileContainer: {
    "& button": {
      height: "100%",
    },
  },
  mobileRightActions: {
    display: "flex",
    alignItems: "center",
    float: "right",
    height: 80,
  },
  [theme.breakpoints.down("md")]: {
    header: {
      paddingLeft: 0,
    },
    headerContainer: {
      flexDirection: "column",
      backgroundColor: theme.palette.primary.main,
      height: "100vh",
      "& svg": {
        marginTop: 10,
      },
    },
    links: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  [theme.breakpoints.down("sm")]: {
    mobileContainer: {
      display: "flex",
      alignItems: "center",
      "& a": {
        textAlign: "center",
      },
    },
    mobileRightActions: {
      flex: 1,
      flexDirection: "row-reverse",
    },
  },
  popup: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(30),
      height: "30%",
      "& h2": {
        fontSize: "15px !important",
      },
    },
  },
}));
const Desktop = ({ children }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data: session, isLoggedIn } = useSession();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dialogs = useDialogs();
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState(null);

  const { data, loading } = useResource(isLoggedIn && APIEndpoints.USER.DECKS.PLAYABLES);

  const goToMyRooster = () => {
    router.push(routes.MY_ROSTER);
  };
  const launchGame = async () => {
    if (!session) {
      router.push(routes.SIGN_IN);
    }
    if (!loading) {
      if (!data.data.length) {
        dialogs.create({
          title: "You don't have any deck to start the game",
          subtitle: (
            <Typography>
              if you don't know how to build one click
              <a href="https://www.youtube.com/watch?v=Y_bsIMKzX-M" className={classes.buttonHere} target="_blank" rel="noreferrer"> here</a>
            </Typography>
          ),
          onContinue: goToMyRooster,
          className: classes.popup,
        });
      } else {
        window.open(`${process.env.NEXT_PUBLIC_GAME_URL}?jwt=${session.accessToken}`, "_blank");
      }
    }
  };
  const displayLogo = (onClick) => {
    const logoClicked = () => {
      if (onClick) onClick();
    };
    return (
      <Link passHref href={routes.INDEX}>
        <Button onClick={logoClicked}>
          <LogoCSDBlack height={50} />
        </Button>
      </Link>
    );
  };
  const displayRightActions = () => (
    <HorizontalArragement>
      {session ? (
        <>
          <ProfilePopover>
            <Button id="btnUserProfile">
              <Avatar src={session.user.avatar} />
            </Button>
          </ProfilePopover>
        </>
      ) : (
        <Link passHref href={routes.SIGN_IN_AND_REDIRECT(router.asPath)}>
          <Button id="btnGetStarted" variant="contained" color="secondary">
            <Typography variant="h6">
              {t("GET_STARTED")}
            </Typography>
          </Button>
        </Link>
      )}
      {!isMobile && (
        <Button
          id="btnPlayNow"
          variant="contained"
          color="secondary"
          onClick={launchGame}
        >
          <Typography variant="h6">
            {t("PLAY_NOW")}
          </Typography>
        </Button>
      )}
    </HorizontalArragement>
  );

  const displayLinks = () => {
    const menuItems = ["MY_ROSTER", "STORE", "LEADERBOARD", "WHITEPAPER"];
 
    return (
      <Box className={classes.links}>
        {menuItems.map((item) => (
          item !== "WHITEPAPER"
            ? (
              <ActiveLink
                key={item}
                href={routes[item]}
                title={t(item)}
                onClick={() => setDrawerOpen(false)}
              />
            ) : (
              <Box key={item}>
                <ActiveLink
                  href={{ }}
                  title={t(item)}
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                />
                <Popover
                  id="simple-popover"
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Box>
                    <MenuItem
                      onClick={() => window.open("https://drive.google.com/file/d/1OxmKXMc0I9iE91D4xTihUgOtHW1uszP8/view?usp=sharing", "_blank")}
                    >
                      English
                    </MenuItem>
                    <MenuItem
                      onClick={() => window.open("https://drive.google.com/file/d/1cZvgXXEDbbnRLjo3b4aHd-2CqFBE59q1/view?usp=sharing", "_blank")}
                    >
                      Spanish
                    </MenuItem>
                  </Box>
                </Popover>
              </Box>
            )
        ))}
      </Box>
    );
  };
  const displayMobile = () => {
    const handleDrawerOpen = () => setDrawerOpen(true);
    const handleDrawerClose = () => setDrawerOpen(false);
    return (
      <Container className={classes.mobileContainer}>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>
        {displayLogo()}
        <Box className={classes.mobileRightActions}>
          {displayRightActions()}
        </Box>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <Container className={classes.headerContainer}>
            {displayLogo(handleDrawerClose)}
            {displayLinks()}
            <Box display="flex" alignItems="flex-end" flex={1} mb={4}>
              <Button
                id="btnPlayNow"
                variant="contained"
                color="secondary"
                onClick={launchGame}
              >
                <Typography variant="h4">
                  {t("PLAY_NOW")}
                </Typography>
              </Button>
            </Box>
          </Container>
        </Drawer>
      </Container>
    );
  };
  const displayDesktop = () => (
    <Container maxWidth="xl" className={classes.headerContainer}>
      {displayLogo()}
      {displayLinks()}
      <Box flexGrow={1} />
      {displayRightActions()}
    </Container>
  );
  return (
    <Box className={classes.container}>
      <AppBar className={classes.header} elevation={0}>
        {isMobile ? displayMobile() : displayDesktop()}
      </AppBar>
      <Box className={classes.contentContainer}>{children}</Box>
      <Box className={classes.footer}>
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs={12} md={4}>
            <Box
              display="inline-flex"
              flexDirection="column"
              justifyContent={isMobile ? "center" : "flex-start"}
              gridGap={24}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.nervos.org"
                style={{ display: "flex", alignItems: "center", gap: 12, color: "#fff" }}
              >
                <img alt="" src={nervosIcon.src} />
                nervos.org
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://ratherlabs.com"
                style={{ display: "flex", alignItems: "center", gap: 12, color: "#fff" }}
              >
                <img alt="" src={ratherIcon.src} />
                ratherlabs.com
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.gravitygaming.io/"
                style={{ display: "flex", alignItems: "center", gap: 12, color: "#fff" }}
              >
                <img alt="" src={gravityGamingIcon.src} width={50} />
                gravitygaming.io
              </a>

            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box textAlign="center">
              <LettersCSDRed className={classes.logo} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" justifyContent="flex-end">
              <Box
                display="flex"
                flexDirection="column"
                alignItems={isMobile ? "center" : "flex-start"}
                width={isMobile ? "100%" : "245px"}
              >
                <Box>
                  <Typography variant="caption" style={{ color: "#fff" }}>
                    Don&apos;t miss what&apos;s next,
                    join our Community
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gridGap={24} mt={2}>
                  <a target="_blank" rel="noreferrer" href="https://twitter.com/CSDGame">
                    <img alt="" src={twitterIcon.src} />
                  </a>
                  <a target="_blank" rel="noreferrer" href="https://discord.com/invite/5VEe4T52hC">
                    <img alt="" src={discordIcon.src} />
                  </a>
                  <a target="_blank" rel="noreferrer" href="https://medium.com/celebrity-smack-down">
                    <img alt="" src={mediumIcon.src} />
                  </a>
                  <a target="_blank" rel="noreferrer" href="https://www.reddit.com/r/CSDGame">
                    <img alt="" src={redditIcon.src} />
                  </a>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default Desktop;
